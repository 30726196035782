import { ProjectPriorityScoreCard } from "~/services/shared/projects/models/project-priority-score-card";
import { ICreditForecast, ICreditsMarket, ICustomerProduct, IDidNotPursueReason, IInvestmentType, ITechnicalReviewRecommendation, ITechnicalReviewStatus, ISustainableDevelopmentGoalInput } from "./common";
import { ICountry, IGeoCoordinate, IPostalAddress } from "./location";
import { MethodologyDto } from "~/components/shared/methodology-selector/methodology.dto";

export class ProjectConstants {
    static MaxProjectDescriptionLength: number = 255;
    static LeadStatusId = 14;
    static DidNotPursueStatusId = 11;
    static agreementSignedStatusId = 3;
    static definitiveAgreementNegotiationStatusId = 2;
    static completedStatusId = 8;
    static retiredStatusId = 9;
    static otherDidNotPursueReasonId = 7;
} 

export interface ICvxProjectType {
    id: number;
    name: string;
    cvxProjectScopeName: string;
    cvxProjectScope: ICvxProjectScope;
}

export interface ICvxProjectScope {
    name: string;
    longName: string;
    cvxProjectTypes: ICvxProjectType[];
}

export interface ICvxProjectOwner {
    accountId: number;
    accountName: string;
    percentOwned: number;
}

export interface ICvxProjectStatus {
    id: number;
    name: string;
    category: string;
    description: string;
    order: number;
}

export interface IProjectScreeningReturnsMetric {
    id: number;
    name: string;
}

export interface ICvxProjectScreening {
    isAccretiveToPorfolio: boolean;
    isScalable: boolean;
    isActionable: boolean;
    reviewPassed: boolean;
    totalCreditsForecast: number;
    reductionType: string;
    registryType: string;
    hasCobenefits: boolean;
    isFungible: boolean;
    estimatedAbatementCost: number;
    returnsMetric: IProjectScreeningReturnsMetric;
    estimatedMidInternalRateOfReturn: number;
    estimatedMinimumInternalRateOfReturn: number;
    estimatedMaximumInternalRateOfReturn: number;
    midNetPresentValuePerTonne: number;
    minNetPresentValuePerTonne: number;
    maxNetPresentValuePerTonne: number;
    isAccretiveToPorfolioNotes: string;
    isScalableNotes: string;
    isActionableNotes: string;
    isReviewPassedNotes: string;
    priceDeckId: number;
    confidenceEstimate: number;
}

export interface ICvxProjectPipe {
    name: string;
    cvxProjectType: ICvxProjectType,
    description: string;
    location?: IPostalAddress;
    site?: IGeoCoordinate;
    cvxProjectDeveloper: ICvxProjectDeveloper;
    operator?: string;
    totalCreditsForecast: number;
    status: string;
    didNotPursueReasonId: number;
    didNotPursueReasonOther: string;
    executionStartDate?: Date | null;
    ndaExecutionDate?: Date | null;
    firstYearOfIssuance: number;
    fundingOrSpending: number;
    contactId?: string;
    contact?: string;
    verifier?: string;
    attachments?: string;
    sustainableDevelopmentGoalInputs?: ISustainableDevelopmentGoalInput[];
    owners?: ICvxProjectOwner[];
    barriers?: string;
    priority?: number;
    projectStatusNotes?: string;
    originatorId?: string;
    originator?: string;
    legalLeadId?: string;
    legalLead?: string;
    notes?: string;
    eligibility?: string;
    carbon?: string;
    integrity?: string;
    feasibility?: string;
    isGlobalLocation?: boolean;
    termSheetLeadId?: string;
    termSheetLead?: string;
    negotiatorId?: string;
    negotiator?: string;
    technicalLeadId?: string;
    technicalLead?: string;
}

export interface IWsjfScorecard {
  businessValue?: number;
  timeCriticality?: number;
  riskReductionOpportunityEnablementValue?: number;
  estimatedImplementationEffort?: number;
  wsjfScore?: number;
}

export interface ICvxProjectDeveloper {
    id: number;
    name: string;
    salesforceAccountId: string 
}

// 
export interface ICvxProject {
    id: number;
    contactId?: string,
    contact: string;
    createdOn: Date;
    modifiedOn: Date;
    description: string;
    cvxProjectDeveloper: ICvxProjectDeveloper;
    executionStartDate?: Date | null;
    ndaExecutionDate?: Date | null;
    firstYearOfIssuance: number;
    fundingOrSpending: number;
    investmentType: IInvestmentType;
    isArticle6Eligible: boolean;
    isAvoidance: boolean;
    isRemoval: boolean;
    location: IPostalAddress;
    country?: ICountry;
    metaRegistryProjectId: string;
    methodologyId: number | null;
    methodology: MethodologyDto;
    name: string;
    cvxProjectType: ICvxProjectType;
    operator: string;
    owners: ICvxProjectOwner[];
    site: IGeoCoordinate;
    status: ICvxProjectStatus;
    sustainableDevelopmentGoals: ISustainableDevelopmentGoalInput[];
    totalCreditsForecast: number;
    totalSpend: number;
    verifier: string;
    yearlyCreditForecasts: ICreditForecast[];
    yearlyCreditForecastsFunded: ICreditForecast[];
    yearlyCreditForecastsCurrent: ICreditForecast[];
    didNotPursueReason: IDidNotPursueReason;
    didNotPursueReasonOther: string;
    creditsMarket: ICreditsMarket;
    barriers: string;
    priority: number;
    projectStatusNotes: string;
    originatorId?: string;
    originator: string;
    legalLeadId?: string;
    legalLead: string;
    notes: string;
    product: ICustomerProduct;
    projectPortfolioId: string;
    termSheetLeadId?: string;
    termSheetLead?: string;
    negotiatorId?: string;
    negotiator?: string;
    contractedLifetimeVolume?: number;
    maximumAccessLifetimeVolume?: number;
    projectLife?: number;
    annualVolume?: number;
    technicalLeadId?: string;
    technicalLead?: string;
    technicalReviewNotes?: string;
    technicalReviewCompletionDate?: Date;
    technicalReviewStatus?: ITechnicalReviewStatus;
    technicalReviewRecommendation?: ITechnicalReviewRecommendation;
    lastUpdated: Date;
    eligibility?: string;
    carbon?: string;
    integrity?: string;
    feasibility?: string;
    wsjfScorecard?: IWsjfScorecard;
    wsjfScorecardTimeCriticality?: IWsjfScorecard;
    wsjfScorecardBusinessValue?: IWsjfScorecard;
    wsjfRiskReductionValue?: IWsjfScorecard;
    wsjfEstimatedEffort?: IWsjfScorecard;
    wsjfScore?: IWsjfScorecard;
    isGlobalLocation?: boolean;
    externalTechResource?: string;
    priorityScoreCard: ProjectPriorityScoreCard;
    projectTypeRating?: number;
    geographyRating?: number;
    developerRating?: number;
    fungibilityRating?: number;
    scaleRating?: number;
    economicsRating?: number;
    initialScreeningPriority?: number;
    priorityWithEconomics?: number;
}

export interface IProjectActionResult {
    projectId: string,
    warnings: string[]
}
