<div class="content">
    <div class="data">
        <div class="description">
            <div class="header">Description</div>
            <div class="description-data">
                <div *ngIf="project.description !== ''" class="text">{{ project.description }}</div>
                <div *ngIf="project.description === ''" class="text none">No description provided.</div>
            </div>
        </div>

        <br /><br />
        
        <div class="goals">
            <div class="header">Sustainable Development Goals (SDG)</div>
            <div class="goal-data">
                <div *ngIf="project.sustainableDevelopmentGoals.length > 0">
                    <ng-container *ngFor="let goal of project.sustainableDevelopmentGoals">
                        <img [alt]="goal.description" [title]="goal.description" [src]="'assets/images/E_WEB_INVERTED_' + (goal.id | number: '2.0') + '.png'" class="goal" />
                    </ng-container>
                </div>
                <div *ngIf="project.sustainableDevelopmentGoals.length === 0">
                    <img alt="No Goals" title="No Goals" [src]="'assets/images/No-Goals.png'" class="goal" />
                </div>
            </div>
        </div>
    </div>
    <div class="info">
        <table class="table-x">
            <caption style="display: none;">Details</caption>
            <tbody>
                <tr>
                    <th scope="col">Project ID:</th>
                    <td [title]="project.id || '-'">{{ project.id || '-' }}</td>
                </tr>
                <tr>
                    <th scope="col">Status:</th>
                    <td>{{ project.status || '-' }}</td>
                </tr>
                <tr>
                    <th scope="col">Registry:</th>
                    <td title="{{ project.registryLongName }}"><a [href]="project.registryUrl" target="_blank">{{ project.registry }}</a></td>
                </tr>
                <tr>
                    <th scope="col">Country:</th>
                    <td>{{ project.country || '-' }}</td>
                </tr>
                <tr>
                    <th scope="col">Scope:</th>
                    <td>{{ project.scope || '-' }}</td>
                </tr>
                <tr>
                    <th scope="col">Type:</th>
                    <td>{{ project.type || '-' }}</td>
                </tr>
                <tr>
                    <th scope="col">Protocol:</th>
                    <td>{{ project.protocol || '-' }}</td>
                </tr>
                <tr>
                    <th scope="col">Price Estimate:</th>
                    <td>{{ formatDirectPriceEstimate(project.directPriceEstimate) | currency }}</td>
                </tr>
                <tr>
                    <th scope="col">Owner:</th>
                    <td>{{ project.owner || '-' }}</td>
                </tr>
                <tr>
                    <th scope="col">Developer:</th>
                    <td>{{ project.developer || '-' }}</td>
                </tr>
                <tr>
                    <th scope="col">Verifier:</th>
                    <td>{{ project.verifier || '-' }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
