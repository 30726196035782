import { Injectable } from '@angular/core';

import { HttpService } from '~/services/core/http.service';

import { PipeCvxProject } from '~/classes/shared/transform';

import { RequestParameters } from '~/models/shared/request-parameters';
import { ICvxProject, ICvxProjectScreening, ICvxProjectStatus, IProjectActionResult, ProjectConstants } from '~/models/shared/cvxproject';
import { Observable, catchError, from, of } from 'rxjs';
import { WsjfScoreCard, WsjfScoreCardInputModel, WsjfScoreCardKpi } from './models/wsjf-score-card.model';
import { ForecastTypeEnum } from '~/pages/projects/project-detail/forecast/model/forecast-type.enum';
import { HttpEvent } from '@angular/common/http';
import { ICreditForecastsUploadResponse } from '~/models/shared/credit-forecasts-upload-response.model';
import { CvxProjectScreening } from '~/models/shared/cvx-project-screening.model';
import { CreditForecastUpdateWrapper } from '~/pages/projects/project-detail/forecast/model/credit-forecast-update-wrapper.model';
import { FilterInfo } from '~/models/shared/filterInfo';
import { ProjectPriorityScoreCard, ProjectPriorityScoreCardInputModel } from './models/project-priority-score-card';
import { IProjectPermissionResult } from './models/project-permission-result';
import { ProjectAddContacts } from '~/pages/projects/components/project-add/contacts/project-add-contacts.component';

@Injectable()
export class ProjectService {

    constructor(
        private httpService: HttpService,
    ) { }

    public async getProjectPermissions(): Promise<IProjectPermissionResult> {
        try {
            return await this.httpService.Get('/projects/permissions').then((data: IProjectPermissionResult) => {
                return data;
            });
        } catch (error: any) {
            throw error;
        }
    }

    public async searchProjects(parameters: RequestParameters): Promise<{
        results: {
            values: ICvxProject[],
            metadata: any
        },
        summation: any
    }> {
        try {
            return await this.httpService.Post("/projects/search", parameters).then((data: any) => {
                return data;
            });
        } catch (error: any) {
            throw error;
        }
    }

    public async getProject(id: number): Promise<any> {
        try {
            return await this.httpService.Get(`/projects/${id}`).then((data: any) => {
                return data;
            });
        } catch (error: any) {
            throw error;
        }
    }

    public async getTypes(): Promise<any> {
        try {
            return await this.httpService.Get("/cvx-project-scopes").then((data: any) => {
                return data;
            });
        } catch (error: any) {
            throw error;
        }
    }

    public async getStatuses(): Promise<any> {
        try {
            return await this.httpService.Get("/projects/project-statuses").then((data: any) => {
                return data;
            });
        } catch (error: any) {
            throw error;
        }
    }

    // Returns a unique list of categories from the given status list
    public extractUniqueStatusCategories(statuses: ICvxProjectStatus[]): string[] {
        return (statuses.map(s => s.category).filter((value, index, self) => self.indexOf(value) === index));
    }

    public async getDNPStatuses(): Promise<any> {
        try {
            return await this.httpService.Get("/projects/did-not-pursue-reasons").then((data: any) => {
                return data;
            });
        } catch (error: any) {
            throw error;
        }
    }

    public async getScreening(id: number): Promise<any> {
        try {
            return await this.httpService.Get(`/projects/${id}/opportunity-screening`).then((data: any) => {
                return data;
            });
        } catch (error: any) {
            throw error;
        }
    }

    public async getTechnicalReviewStatuses(): Promise<any> {
        try {
            return await this.httpService.Get("/projects/project-technical-review-statuses").then((data: any) => {
                return data;
            });
        } catch (error: any) {
            throw error;
        }
    }

    public async getTechnicalReviewRecommendations(): Promise<any> {
        try {
            return await this.httpService.Get("/projects/project-technical-review-recommendations").then((data: any) => {
                return data;
            });
        } catch (error: any) {
            throw error;
        }
    }

    public async getProducts(): Promise<any> {
        try {
            return await this.httpService.Get("/projects/project-products").then((data: any) => {
                return data;
            });
        } catch (error: any) {
            throw error;
        }
    }

    public async deleteProject(id: number): Promise<boolean> {
        try {
            return await this.httpService.Delete(`/projects/${id}`).then(() => {
                return true;
            });
        } catch (error: any) {
            throw error;
        }
    }

    public saveProject(project: ICvxProject): Promise<IProjectActionResult> {
        return project.id
            ? this.updateProject(project).then((data: any) => {
                return data;
            }).catch((error: any) => {
                throw error;
            })
            : this.createProject(project).then((data: any) => {
                return data;
            }).catch((error: any) => {
                throw error;
            });
    }

    public async saveScreening(id: number, screening: ICvxProjectScreening): Promise<any> {
        try {
            return await this.httpService.Put(`/projects/${id}/opportunity-screening`, screening).then(() => {
            }).catch((error: any) => {
                throw error;
            });
        } catch (error: any) {
            throw error;
        }
    }

    private async createProject(project: ICvxProject): Promise<IProjectActionResult> {
        try {
            let pipeProject: PipeCvxProject = new PipeCvxProject();
            let projectPipe = pipeProject.transform(project);

            return await this.httpService.Post("/projects", projectPipe).then((data: any) => {
                return data;
            }).catch((error: any) => {
                throw error;
            });
        } catch (error: any) {
            throw error;
        }
    }

    private async updateProject(project: ICvxProject): Promise<IProjectActionResult> {
        try {
            let pipeProject: PipeCvxProject = new PipeCvxProject();
            let projectPipe = pipeProject.transform(project);

            return await this.httpService.Patch(`/projects/${project.id}`, projectPipe).then((data) => {
                return data;
            }).catch((error: any) => {
                throw error;
            });
        } catch (error: any) {
            throw error;
        }
    }

    //#region Priority Score Card

    public getPriorityScoreCard(projectId: number): Observable<ProjectPriorityScoreCard | null> {
        const requestUri = `/projects/${projectId}/priority-score-card`;
        return from(this.httpService.Get(requestUri,));
    }

    public setPriorityScoreCard(
        projectId: number,
        value: ProjectPriorityScoreCardInputModel
    ): Observable<ProjectPriorityScoreCard> {
        const requestUri = `/projects/${projectId}/priority-score-card`;
        if (!value) {
            throw new Error("Value is required.");
        }
        return from(this.httpService.Put(requestUri, value));
    }

    //#endregion

    //#region WSJF Score Card   

    public addWsjfScoreCard(projectId: number, scoreCard: WsjfScoreCardInputModel): Observable<WsjfScoreCard> {
        const requestUri = `/projects/${projectId}/wsjf-score-card`;
        return from(this.httpService.Post(requestUri, scoreCard));
    }

    public getWsjfScoreCard(projectId: number): Observable<WsjfScoreCard | null> {
        const requestUri = `/projects/${projectId}/wsjf-score-card`;
        return from(this.httpService.Get(requestUri,));
    }

    public updateWsjfScoreCard(projectId: number, scoreCard: WsjfScoreCardInputModel): Observable<WsjfScoreCard> {
        const requestUri = `/projects/${projectId}/wsjf-score-card`;
        return from(this.httpService.Patch(requestUri, scoreCard));
    }
    public getScoreCardKpi(): Observable<WsjfScoreCardKpi> {
        // mocking data as backend does not exist yet.
        return of({
            lowestValue: Object.assign({}, {
                businessValue: 2,
                timeCriticality: 1,
                riskReductionOpportunityEnablementValue: 2,
                estimatedImplementationEffort: 5
            }) as WsjfScoreCard,
            highestValue: Object.assign({}, {
                businessValue: 10,
                timeCriticality: 9,
                riskReductionOpportunityEnablementValue: 7,
                estimatedImplementationEffort: 5
            }) as WsjfScoreCard,
            lowestEffort: Object.assign({}, {
                businessValue: 10,
                timeCriticality: 5,
                riskReductionOpportunityEnablementValue: 7,
                estimatedImplementationEffort: 1
            }) as WsjfScoreCard,
            highestEffort: Object.assign({}, {
                businessValue: 10,
                timeCriticality: 5,
                riskReductionOpportunityEnablementValue: 7,
                estimatedImplementationEffort: 8
            }) as WsjfScoreCard,
            wsjfScoreAverage: { value: 14, effort: 7 },
            wsjfScoreHigh: { value: 27, effort: 3 },
            wsjfScoreLow: { value: 4, effort: 4 }
        } as WsjfScoreCardKpi)
    }

    //#endregion
    public saveCreditsForecast(projectId: number, updateWrapper: CreditForecastUpdateWrapper): Observable<void> {
        const uri = `/projects/${projectId}/credits-forecast`;

        return from(this.httpService.Put(uri, updateWrapper));
    }

    public uploadCreditForecastsData(cvxProjectId: number, forecastType: ForecastTypeEnum, file: File): Observable<HttpEvent<ICreditForecastsUploadResponse>> {
        const requestUri = `/projects/${cvxProjectId}/credits-forecast-upload?forecastType=${forecastType}`;

        const formData: FormData = new FormData();
        formData.append('formFile', file, file.name);

        const options = {
            reportProgress: true,
            observe: 'events'
        };

        return from(this.httpService.Post(requestUri, formData, options));
    }

    public parseCreditForecastsData(cvxProjectId: number, forecastType: ForecastTypeEnum, worksheet: string, uploadResponse: ICreditForecastsUploadResponse) {
        const requestUri = `/projects/${cvxProjectId}/credits-forecast-parse?forecastType=${forecastType}&worksheet=${worksheet}`;

        return from(this.httpService.Post(encodeURI(requestUri), uploadResponse));
    }

    public getOpportunityScreening(projectId: number): Observable<CvxProjectScreening> {
        const requestUri = `/projects/${projectId}/opportunity-screening`;

        return from(this.httpService.Get(requestUri)).pipe(
            catchError(this.httpService.handleError())
        );
    }

    public getProjectStatusHistory(projectId: number, take: number = 5): Observable<CvxProjectScreening> {
        const requestUri = `/projects/${projectId}/status-history/${take}`;

        return from(this.httpService.Get(requestUri)).pipe(
            catchError(this.httpService.handleError())
        );
    }

    public async getFilters(fieldNames: string[]): Promise<FilterInfo[]> {
        try {
            let queryString = "";
            fieldNames.forEach((fieldName) => {
                queryString = queryString === "" ? `fields=${fieldName}` : `${queryString}&fields=${fieldName}`;
            });
            const response = await this.httpService.Get(`/projects/filters?${queryString}`);
            return response;
        } catch (error: any) {
            throw error;
        }
    }


    // Project Validation
    public isRequiredByStatus(statusId: number, field: string): boolean {

        const requiredForLeadStatus: string[] = [
            'name',
            'status',
            'contact'
        ];

        const requiredFields: string[] = [
            ...requiredForLeadStatus,
            'priority',
            'cvxProjectType',
            'product',
            'investmentType',
            'country',
            'cvxProjectDeveloper',
            'methodology'
        ];

        const requiredByStatusMap = new Map<number, string[]>([
            [ProjectConstants.LeadStatusId, requiredForLeadStatus],
            [ProjectConstants.DidNotPursueStatusId, [...requiredFields, 'didNotPursueReason', 'executionStartDate']],
            [ProjectConstants.agreementSignedStatusId, [...requiredFields, 'negotiator', 'executionStartDate']],
            [ProjectConstants.definitiveAgreementNegotiationStatusId, [...requiredFields, 'negotiator']],
            [ProjectConstants.completedStatusId, [...requiredFields, 'negotiator']],
            [ProjectConstants.retiredStatusId, [...requiredFields, 'negotiator']]
        ]);

        const statusFields = requiredByStatusMap.get(statusId);

        if (statusFields) {
            return statusFields.findIndex(f => f === field) !== -1;
        }

        return requiredFields.findIndex(f => f === field) !== -1;
    }

    public isRequired(project: ICvxProject, field: string): boolean {
        if (!project) {
            return false;
        }

        const statusId = project.status ? project.status?.id : 0;

        return this.isRequiredByStatus(statusId, field);
    }

    public isValid(project: ICvxProject, partialValidationFields: string[] = []): boolean {
        return project &&
            this.validateStandardField('cvxProjectDeveloper', project, partialValidationFields) &&
            this.validateStandardField('contact', project, partialValidationFields) &&
            this.validateStandardField('negotiator', project, partialValidationFields) &&
            this.validateStandardField('executionStartDate', project, partialValidationFields) &&
            this.hasValidName(project, partialValidationFields) &&
            this.hasValidStatus(project, partialValidationFields) &&
            this.hasValidPriority(project, partialValidationFields) &&
            this.hasValidProjectType(project, partialValidationFields) &&
            this.hasValidProduct(project, partialValidationFields) &&
            this.hasValidInvestmentType(project, partialValidationFields) &&
            this.hasValidCountry(project, partialValidationFields) &&
            this.hasValidDidNotPursueReason(project, partialValidationFields) &&
            this.hasMethodology(project, partialValidationFields);
    }

    private hasValidName(project: ICvxProject, fields: string[] = []): boolean {
        if (fields.length > 0 && !fields.includes('name')) {
            return true;
        }
        return !!project.name?.trim();
    }

    private hasValidStatus(project: ICvxProject, fields: string[] = []): boolean {
        if (fields.length > 0 && !fields.includes('status')) {
            return true;
        }

        return project.status?.id !== 0;
    }

    private hasValidPriority(project: ICvxProject, fields: string[] = []): boolean {

        const fieldName = 'priority';
        if (fields.length > 0 && !fields.includes(fieldName)) {
            return true;
        }

        if (!this.isRequiredByStatus(project.status?.id, fieldName)) {
            return true;
        }

        return project.priority !== null && project.priority !== 0;
    }

    private hasValidProjectType(project: ICvxProject, fields: string[] = []): boolean {
        const fieldName = 'cvxProjectType';
        if (fields.length > 0 && !fields.includes(fieldName)) {
            return true;
        }

        if (!this.isRequiredByStatus(project.status?.id, fieldName)) {
            return true;
        }

        return project.cvxProjectType && project.cvxProjectType.id !== undefined && project.cvxProjectType?.id !== 0;
    }

    private hasValidProduct(project: ICvxProject, fields: string[] = []): boolean {
        const fieldName = 'product';
        if (fields.length > 0 && !fields.includes(fieldName)) {
            return true;
        }

        if (!this.isRequiredByStatus(project.status?.id, fieldName)) {
            return true;
        }

        return project.product && project.product.id !== undefined && project.product?.id !== 0;
    }

    private hasValidInvestmentType(project: ICvxProject, fields: string[] = []): boolean {
        const fieldName = 'investmentType';
        if (fields.length > 0 && !fields.includes(fieldName)) {
            return true;
        }

        if (!this.isRequiredByStatus(project.status?.id, fieldName)) {
            return true;
        }

        return !!project.investmentType?.name?.trim();
    }

    private hasValidCountry(project: ICvxProject, fields: string[] = []): boolean {
        const fieldName = 'country';
        if (fields.length > 0 && !fields.includes(fieldName)) {
            return true;
        }

        if (!this.isRequiredByStatus(project.status?.id, fieldName)) {
            return true;
        }

        return (project.location && project.location.addressCountry && (project.location.addressCountry?.id ?? 0) > 0) || project.isGlobalLocation === true;
    }

    private hasValidDidNotPursueReason(project: ICvxProject, fields: string[] = []): boolean {
        const fieldName = 'didNotPursueReason';
        if (fields.length > 0 && !fields.includes(fieldName)) {
            return true;
        }

        if (!this.isRequiredByStatus(project.status?.id, fieldName)) {
            return true;
        }

        if (project.status?.id === ProjectConstants.DidNotPursueStatusId) {
            if (!project.didNotPursueReason || !project.didNotPursueReason.id || project.didNotPursueReason?.id <= 0) {
                return false;
            }

            if (project.didNotPursueReason.id === ProjectConstants.otherDidNotPursueReasonId &&
                (!project.didNotPursueReasonOther || project.didNotPursueReasonOther?.trim() === '')) {
                return false;
            }
        }

        return true;
    }

    private hasMethodology(project: ICvxProject, fields: string[] = []): boolean {
        const fieldName = 'methodology';
        if (fields.length > 0 && !fields.includes(fieldName)) {
            return true;
        }

        if (!this.isRequiredByStatus(project.status?.id, fieldName)) {
            return true;
        }

        return (project.methodologyId && project.methodologyId !== null) || (project.methodology && project.methodology.id !== undefined && project.methodology?.id !== 0); 
    }

   
    private validateStandardField(fieldName: string, project: ICvxProject, partialValidationFields: string[] = []): boolean {
        if (partialValidationFields.length > 0 && !partialValidationFields.includes(fieldName)) {
            return true;
        }

        if (!project[fieldName] || project[fieldName] == null) {
            return !this.isRequiredByStatus(project.status.id, fieldName);
        }

        return true;
    }
}