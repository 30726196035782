<div class="content">
    <div class="data">
        <div class="description">
            <div class="header">Description
                
            </div>
            <div class="description-data">
                <div *ngIf="!isEdit && project.description !== ''" class="notes padding">{{ project.description }}</div>
                <div *ngIf="!isEdit && project.description === ''" class="notes padding none">No description provided.</div>
                <div *ngIf="isEdit" style="padding: 10px;">
                    <textarea class="edit required value" [attr.maxlength]="maxDescriptionLength" rows="3" [value]="projectEdit.description" (input)="setDescription($event)"></textarea>
                </div>
                <div *ngIf="isEdit" class="counter">
                   <counter [current]="projectEdit && projectEdit.description? projectEdit.description.length : project.description?.length" [max]="maxDescriptionLength"></counter>
                 </div>
            </div>
        </div>

        <br />

        <table *ngIf="!isEdit" class="table-z">
            <caption style="display: none;">Numbers-ReadONLY</caption>
            <tbody>
                <tr>
                    <th scope="col"><span class="label">Total Funding/Spending:</span> ${{ (project.fundingOrSpending) ? (project.fundingOrSpending | number) + ' (MM)' : '-' }}</th>
                    <th scope="col"><span class="label">Total Carbon Credits Forecast:</span> {{ (project.totalCreditsForecast) ? (project.totalCreditsForecast | number) + ' (MMTCO2e)' : '-' }}</th>
                </tr>
                <tr>
                    <th scope="col"><span class="label">Contracted Lifetime Vol:</span> {{ (project.contractedLifetimeVolume) ? (project.contractedLifetimeVolume | number) + ' (MTCO2e)' : '-' }}</th>
                    <th scope="col"><span class="label">Max Access Lifetime Vol:</span> {{ (project.maximumAccessLifetimeVolume) ? (project.maximumAccessLifetimeVolume | number) + ' (MTCO2e)' : '-' }}</th>
                </tr>
                <tr>
                    <th scope="col"><span class="label">Contracted Annual Vol:</span> {{ (project.annualVolume) ? (project.annualVolume | number) + ' (MTCO2e)' : '-' }}</th>
                </tr>
            </tbody>
        </table>

        <table *ngIf="isEdit" class="table-z">
            <caption style="display: none;">Numbers</caption>
            <tbody>
                <tr>
                    <th scope="col"><span class="label">Total Funding/Spending</span></th>
                    <td><sdk-textbox style="display: inline-flex" [value]="projectEdit?.fundingOrSpending ?? null" width="100px" validCharacters="decimal" (changeCallBackEvent)="infoChange({ field: 'fundingOrSpending', value: $event })"></sdk-textbox> MM</td>

                    <th scope="col"><span class="label">Total Carbon Credits Forecast</span></th>
                    <td><sdk-textbox style="display: inline-flex" [value]="projectEdit?.totalCreditsForecast ?? null" width="100px" validCharacters="decimal" (changeCallBackEvent)="infoChange({ field: 'totalCreditsForecast', value: $event })"></sdk-textbox> MMTCO2e</td>
                </tr>
                <tr>
                    <th scope="col"><span class="label">Contracted Lifetime Vol</span></th>
                    <td><sdk-textbox style="display: inline-flex" [value]="projectEdit?.contractedLifetimeVolume ?? null" width="100px" validCharacters="decimal" (changeCallBackEvent)="infoChange({ field: 'contractedLifetimeVolume', value: $event })"></sdk-textbox> MTCO2e</td>

                    <th scope="col"><span class="label">Max Access Lifetime Vol(MTCO2e)</span></th>
                    <td><sdk-textbox style="display: inline-flex" [value]="projectEdit?.maximumAccessLifetimeVolume ?? null" width="100px"
                         validCharacters="custom" pattern="^\d{1,4}(\.\d{0,2})?$" (changeCallBackEvent)="infoChange({ field: 'maximumAccessLifetimeVolume', value: $event })"></sdk-textbox> MTCO2e</td>
                </tr>
                <tr>
                    <th scope="col"><span class="label">Contracted Annual Vol:</span> {{ (project.annualVolume) ? (project.annualVolume | number) + ' (MTCO2e)' : '-' }}</th>
                </tr>
            </tbody>
        </table>

        <br />

        <table class="table-x">
            <caption style="display: none;">Notes</caption>
            <tbody>
                <tr>
                    <th scope="col" [ngClass]="{ 'required label': isEdit }">Status <fa-icon [icon]="faHistory" [matTooltip]="getProjectStatusHistoryTooltip()"></fa-icon></th>
                    <td *ngIf="!isEdit">{{ project.status.name || '-' }}</td>
                    <td *ngIf="isEdit">
                        <select class="required value" (change)="setStatus($event)">
                            <option *ngIf="!projectEdit.status || projectEdit.status === ''" value="" [selected]="!projectEdit.status || projectEdit.status === ''">...</option>
                            <optgroup *ngFor="let category of projectStatusCategoryList" label="{{category}}">
                                <ng-container *ngFor="let status of projectStatusList">
                                    <option *ngIf="category === status.category" [value]="status | json" [selected]="projectEdit.status?.name === status.name">{{ status.name }}</option>
                                </ng-container>
                            </optgroup>
                        </select>
                    </td>
                </tr>
                <tr *ngIf="showStatusExt">
                    <th scope="col" [ngClass]="{ 'required label': isEdit }">"Did Not Pursue" Reason</th>
                    <td *ngIf="!isEdit">{{ project.didNotPursueReason?.name || '-' }}</td>
                    <td *ngIf="isEdit">
                        <select class="required value" (change)="setDNPStatus($event)">
                            <option value="" [selected]="!projectEdit.didNotPursueReason || projectEdit.didNotPursueReason === ''">...</option>

                            <ng-container *ngFor="let status of projectDNPStatusList">
                                <option [value]="status | json" [selected]="projectEdit.didNotPursueReason?.name === status.name">{{ status.name }}</option>
                            </ng-container>
                        </select>
                    </td>
                </tr>
                <tr *ngIf="showDNPStatusExt">
                    <th scope="col" [ngClass]="{ 'required label': isEdit }">"Other" Reason</th>
                    <td *ngIf="!isEdit"><div class="notes">{{ project.didNotPursueReasonOther || '-' }}</div></td>
                    <td *ngIf="isEdit"><textarea class="edit" rows="5" [value]="projectEdit.didNotPursueReasonOther" (input)="setNotes('didNotPursueReasonOther', $event)"></textarea></td>
                </tr>
                <tr *ngIf="isEdit && isRequired('negotiator')">
                    <th scope="col" class="required label">Negotiator</th>
                    <td *ngIf="isEdit">
                        <user-selection [entity]="entity"
                                        [field]="'negotiator'"
                                        [selectedUserName]="projectEdit.negotiator"
                                        (userSelectedEvent)="userSelectionChange('negotiator', $event)">
                        </user-selection>
                    </td>
                </tr>
                <tr *ngIf="isEdit && isRequired('cvxProjectDeveloper')" class="developer-row" [ngClass]="{edit: isEdit}">
                    <th scope="col" class="required label">Developer</th>
                    <td>
                        <developer-search [currentDeveloper]="project.cvxProjectDeveloper" (developerSelectedEvent)="infoChange({ field: 'cvxProjectDeveloper', value: $event })"> </developer-search>
                    </td>
                </tr>
                <tr>
                    <th scope="col">Status Notes</th>
                    <td *ngIf="!isEdit"><div class="notes">{{ project.projectStatusNotes || '-' }}</div></td>
                    <td *ngIf="isEdit"><textarea class="edit" rows="5" [value]="projectEdit.projectStatusNotes" (input)="setNotes('projectStatusNotes', $event)"></textarea></td>
                </tr>
                <tr>
                    <th scope="col">Notes</th>
                    <td *ngIf="!isEdit"><div class="notes">{{ project.notes || '-' }}</div></td>
                    <td *ngIf="isEdit"><textarea class="edit" rows="5" [value]="projectEdit.notes" (input)="setNotes('notes', $event)"></textarea></td>
                </tr>
                <tr>
                    <th scope="col">Barriers</th>
                    <td *ngIf="!isEdit"><div class="notes">{{ project.barriers || '-' }}</div></td>
                    <td *ngIf="isEdit"><textarea class="edit" rows="5" [value]="projectEdit.barriers" (input)="setNotes('barriers', $event)"></textarea></td>
                </tr>
            </tbody>
        </table>

        <sdk-loading [isLoading]="isLoading"></sdk-loading>
    </div>

    <div class="info">
        <tab-info [project]="project" [isEdit]="isEdit" [status]="ProjectStatus$ | async" [portfolioId]="portfolioId" (changeEvent)="infoChange($event)"></tab-info>
        <br />
        <div class="goals">
            <div class="header">Sustainable Development Goals (SDG)</div>
            <div class="goal-data">
                <div *ngIf="!isEdit && project.sustainableDevelopmentGoals.length > 0">
                    <ng-container *ngFor="let goal of project.sustainableDevelopmentGoals">
                        <img [alt]="goal.description" [title]="goal.description" [src]="'assets/images/E_WEB_INVERTED_' + (goal.id | number: '2.0') + '.png'" class="goal" />
                    </ng-container>
                </div>
                <div *ngIf="!isEdit && project.sustainableDevelopmentGoals.length === 0">
                    <img alt="No Goals" title="No Goals" [src]="'assets/images/No-Goals.png'" class="goal" />
                </div>
                <div *ngIf="isEdit">
                    <ng-container *ngFor="let goal of sustainabilityList">
                        <div class="editGoal">
                            <input type="checkbox" [id]="'goal.' + goal.id" [checked]="goal.selected" (change)="setGoal($event, goal)" />
                            <label [for]="'goal.' + goal.id"><img [alt]="goal.description" [title]="goal.description" [src]="'assets/images/E_WEB_INVERTED_' + (goal.id | number: '2.0') + '.png'" class="goal edit" [ngClass]="{ unchecked : !goal.selected }" /></label>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>        
    </div>
</div>
